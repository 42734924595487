<template>
  <div class="me-friends__list">
    <van-index-bar :index-list="Object.keys(formartList)" :sticky-offset-top="50">
      <template v-for="(key, index) in Object.keys(formartList)">
        <van-index-anchor :index="key" :key="index" />
        <van-cell
          v-for="(contact, _key) in formartList[key]"
          :key="contact.id"
          @click="isSelectModel ? handlerSelectedContact(key,_key): ''"
        >
          <van-icon
            name="checked"
            slot="right-icon"
            size="20px"
            color="#33cc66"
            class="selected"
            v-if="isSelectModel && (contact.selected || oldMember.some((el=>{
              return el.id === contact.senderId
            })))"
          />
          <van-icon
            v-else-if="isSelectModel"
            name="checked"
            slot="right-icon"
            size="20px"
            class="selected"
            color="#e5e5e5"
            />          
          <Avatar
            :src="contact.senderPhoto"
            width="36px"
            height="36px"
            :isGroup="contact.isGroup"
            :id="contact.senderId"
          />
          <!-- $router.push({name:contact.isGroup? 'group':'user',params:{id:contact.senderId}}): '' -->
          <span class="name" @click="!isSelectModel ? handlerToChat({id:contact.senderId,name:contact.senderName},contact.isGroup?'group' : 'user') : ''">{{ contact.senderName }}</span>
          <i v-if="contact.isGroup && contact.owner" class="me-icon-user-solid"></i>
        </van-cell>
      </template>
    </van-index-bar>
  </div>
</template>
<script>
import Pinyin from "pinyin";
import Avatar from "@/components/common/avatar";
import { Base64 } from "js-base64";
export default {
  name: "contact-list",
  components: {
    Avatar
  },
  data() {
    return {
      formartList: {},
      selectedList: []
    };
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    oldMember: {
      type: Array,
      default: () => []
    },
    isSelectModel: {
      default: false
    }
  },
  watch: {
    list: {
      handler(newly) {
        this.formartList = this._handlerFormartList(newly);
      },
      deep: true
    }
  },
  created() {
    this.formartList = this._handlerFormartList(this.list);
  },
  methods: {
    handlerToChat(item,type){
      localStorage.setItem('chatObj',JSON.stringify({
        id:item.id,
        name:item.name,
        notice:false,
        type
      }))
      this.$router.push({
        name:'chatWith',
        params:{
          id:"pai_" + Base64.encode(item.id),
          type:type
        }
      })
    },
    async handlerSelectedContact(key, index) {
      const item = this.formartList[key][index];
      const curUser = {
        avatar: item.senderPhoto,
        id: item.senderId
      };
      if (
        this.oldMember.some(el => {
          return el.id === item.senderId;
        })
      )
        return;
      if (
        !this.selectedList.some(el => {
          return el.id === item.senderId;
        })
      ) {
        this.selectedList.push(curUser);
        this.formartList[key][index].selected = true;
        this.$forceUpdate();
      } else {
        this.selectedList.some((el, index) => {
          if (el.id === item.senderId) this.selectedList.splice(index, 1);
        });
        this.formartList[key][index].selected = false;
        this.$forceUpdate();
      }
      this.$emit("change", this.selectedList);
    },
    _handlerFormartList(data) {
      let list = {};
      data.map(item => {
        let name = item.senderName;
        let first = Pinyin(name[0],{
           style: Pinyin.STYLE_NORMAL,
           heteronym: true
        });
        const Index = first[0][0].slice(0, 1).toUpperCase();
        item.labelIndex = Index;
        if (!list[Index]) list[Index] = [];
        if (!list[Index].includes(item)) {
          list[Index].push(item);
        }
      });
      return this.objKeySort(list);
    },
    objKeySort(arys) {
      var newkey = Object.keys(arys).sort();
      var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
      const a_z = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ];
      let endLabel = [];

      for (var i = 0; i < newkey.length; i++) {
        if (a_z.includes(newkey[i])) newObj[newkey[i]] = arys[newkey[i]];
        else endLabel = endLabel.concat(arys[newkey[i]]);
      }

      return Object.assign(newObj, endLabel.length ? { "#": endLabel } : {}); //返回排好序的新对象
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

.selected {
  height: 20px;
}

+b(van-index-bar) {
  +e(sidebar) {
    line-height: 30px;

    span {
      margin: 0.5rem 0 0;
    }
  }

  +b(van-cell) {
    align-items: center;

    +e(value) {
      display: flex;
      align-items: center;
      position: relative;

      .name {
        // height: 36px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 22px;
        line-height 36px;
        flex 1
      }

      .me-icon-user-solid {
        position: absolute;
        right: 0;
        color: #33cc66;
        font-size: 18px;
        background: #ffffff;
      }
    }
  }
}
</style>