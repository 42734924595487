import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2015502e&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\h5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2015502e')) {
      api.createRecord('2015502e', component.options)
    } else {
      api.reload('2015502e', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=2015502e&", function () {
      api.rerender('2015502e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/friends/index.vue"
export default component.exports