<template>
  <div class="me-firends" style="padding: 54px 0 ;">
    <!-- <Header>
      <i slot="left-one" class="me-icon-back primary"></i>
    </Header>-->
    <div class="me-friends__search">
      <i v-if="model" class="me-icon-back model" @click="$router.go(-1)"></i>
      <van-search
        v-model="keyword"        
        left-icon
        @search="handlerOnSearch"
        @cancel="handlerOnCancel"
      >
      <!-- :placeholder="$t('Please enter a search term')" -->
        <!-- show-action -->
        <div slot="right-icon" @click="handlerOnSearch">
          <i class="me-icon-search btn"></i>
        </div>
      </van-search>
    </div>
    <van-cell v-if="!model" class="me-friends__group" @click="$router.push({ name: 'myGroups' })">
      <i class="me-icon-group"></i>
      <i class="me-icon-right"></i>
    </van-cell>
    <van-loading v-if="loading" class="loading" color="#33cc66" />
    <contactList
      v-else-if="!loading && list.length"
      :list="list"
      :oldMember="oldGroupMembers.members"
      class="me-firends__contactlist"
      :isSelectModel="model"
      @change="handlerChangeSelected"
    />
    <emptyData v-else />
    <Tabbar v-if="!model" />
    <div class="userList" v-else>
      <div class="users">
        <Avatar
          v-for="(item, index) in selectedUsers"
          :key="index"
          :src="item.avatar"
          width="40px"
          height="40px"
        />
      </div>
      <van-button
        :loading="addUserloading"
        type="primary"
        icon="success"
        :class="{'primary':true ,'disable': !addUsers.length}"
        @click="addUsers.length ? handlerAdduserInGroup() : ''"
      />
    </div>
  </div>
</template>
<script>
// import Header from "@/components/business/h5/header";
import Tabbar from "@/components/common/tabbar";
import { getFriends } from "@/api/imchat";
import { addGroupMembers,addSessionGroup } from "@/api/im";
import Avatar from "@/components/common/avatar";
import contactList from "@/components/business/contact-list";
import emptyData from '@/components/common/emptyData'
import { Base64 } from "js-base64";
export default {
  name: "friends",
  components: {
    Tabbar,
    // Header,
    contactList,
    Avatar,
    emptyData
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 99999,
      list: [],
      language: "",
      keyword: "",
      deepCopyList: [],
      loading: true,
      model: false,
      oldGroupMembers: [],
      selectedUsers: [],
      addUsers: [],
      addUserloading: false
    };
  },
  created() {
    this.language = localStorage.getItem("langCode") || "1";
    if (!this.$store.state.User.userinfo.id) {
      this.$router.push({ name: "login", query: { redirect: "friends" } });
    } else {
      this.handlerGetMyfriends();
    }
    this.model = this.$route.query.selectModel;
    let oldMembersStr = localStorage.getItem("oldGroupMembers");
    if (oldMembersStr) this.oldGroupMembers = JSON.parse(oldMembersStr);
    this.selectedUsers = this.oldGroupMembers.members || [];
    this.addUsers = this.selectedUsers.map(el=>{
      return el.id
    })
  },
  methods: {
    async handlerAdduserInGroup() {
      this.addUserloading = true;
      let chatStr = localStorage.getItem('chatObj')
      const chatObj = chatStr ? JSON.parse(chatStr) : {}
      let result = null
      if(chatObj.type ==='group' && this.oldGroupMembers.id)
        result = await addGroupMembers(
          this.oldGroupMembers.id,
          this.addUsers
        );
      else 
       result = await addSessionGroup(this.addUsers)
      this.addUserloading = !this.addUserloading;
      if (result && result.code === 1) {
        this.$toast.success();
        const data =  result.data ? result.data[0] : chatObj
        if(chatObj.type === 'chat'){
          const newchatobj = {
            name:data.group_name || data.name,
            id:data.group_id || data.id,
            type:'group'
          }
          localStorage.setItem('chatObj',JSON.stringify(newchatobj))
          this.$router.push({
            name:'chatWith', 
            params:{
              id: "pai_" + Base64.encode(data.group_id) || "pai_" + Base64.encode(data.id),
              type:'group'
            }
          })
        }else this.$router.go(-1);        
      }else{
        this.$toast.fail();
      }
    },
    handlerChangeSelected(data) {      
      this.selectedUsers = this.oldGroupMembers.members.concat(data);
      let selected = this.selectedUsers.map(item => {
        return item.id;
      });      
      this.addUsers = selected
      console.log(this.addUsers,this.selectedUsers,selected,'/addUsers');
      
    },
    handlerOnSearch() {
      let newArr = [];
      this.deepCopyList.map(item => {
        if (
          item.senderName.toLowerCase().indexOf(this.keyword.toLowerCase()) !==
          -1
        )
          newArr.push(item);
      });
      this.list = newArr;
    },
    handlerOnCancel() {},

    async handlerGetMyfriends(page = 0) {
      const params = {
        language: this.language,
        userId: this.$store.state.User.userinfo.id,
        pageNum: page,
        pageSize: this.pageSize
      };
      const result = await getFriends(params).catch(e => {});
      const IndexArr = [];
      this.loading = false;
      if (result && result.data) {
        const list = result.data.map(item=>{
          // return {
          //   senderPhoto:item.avatar,
          //   senderName:item.name,
          //   senderId:item.provider_id
          // }
          return {
            senderPhoto:item.profile_photo,
            senderName:item.name,
            senderId:item.provider_id
          }
        });
        this.list =  page === 1 ? list : this.list.concat(list)
        for (var item in this.list) {
          this.deepCopyList[item] = this.list[item];
        }
        const loginUser = this.$store.state.User.userinfo
        this.list.unshift({
         senderName: loginUser.name,
         senderPhoto:loginUser.avatar,
         senderId: loginUser.id
        })
      }
    }
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/index';
.me-firends{
  height:87vh;
  display:flex;
  flex-flow:column
  +e(contactlist){
    height 80vh;
    overflow-y scroll
    position relative
    .van-index-bar{
      .van-index-anchor--sticky{
        top -3.2rem
      }
    }
  }
}
.userList {
  display: flex;
  flex-flow: row;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  border-top: 1px solid #e5e5e5;
  padding: 0.9rem 0;

  .users {
    flex: 1;
    display: flex;
    flex-flow: row;
    overflow-x: scroll;

    .van-image {
      &:first-child {
        margin-left: 0.5rem;
      }
    }
  }

  .van-button {
    .van-loading {
      height: auto;
      padding: 0;
    }

    padding: 0 0.5rem;
    background: #33cc66;
    color: #ffffff;
    border-radius: 5rem;
    min-width: 60px;
    text-align: center;
    font-size: 25px;
    line-height: 40px;
    margin: 0 0.5rem;

    &.disable {
      background: #999999;
      border: 1px solid #999999;
    }
  }
}

.model {
  line-height: 54px;
  margin-left: 0.5rem;
  text-align: center;
}

+b(primary) {
  color: #33cc66;
}

i {
  &.btn {
    font-size: 20px;
  }
}

.van-search__action {
  background: #ffffff;
  line-height: 32px;
}

+b(me-friends) {
  display: flex;
  flex-flow: column;

  +e(search) {
    flex: 1;
    // padding: 0 0.9rem;
    display: flex;
    flex-flow: row;
    position: fixed;
    top: 0;
    left: 0;
    background: #33cc66;
    color: #ffffff;
    width: 100%;
    z-index: 3000;
    height: 54px;

    .van-search {
      // padding: 0.9rem;
      flex: 1;
      // border-radius: 0.2rem;
      background: none !important;
    }
  }

  +e(group) {
    height 62px
    .van-cell__value {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;

      i {
        &:first-child {
          font-size: 22px;
          padding: 0.5rem 0.6rem;
          background: #33cc66;
          color: #ffffff;
          border-radius: 5rem;
        }
      }
    }
  }
}
</style>
