var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-firends", staticStyle: { padding: "54px 0" } },
    [
      _c(
        "div",
        { staticClass: "me-friends__search" },
        [
          _vm.model
            ? _c("i", {
                staticClass: "me-icon-back model",
                on: {
                  click: function($event) {
                    return _vm.$router.go(-1)
                  }
                }
              })
            : _vm._e(),
          _c(
            "van-search",
            {
              attrs: { "left-icon": "" },
              on: { search: _vm.handlerOnSearch, cancel: _vm.handlerOnCancel },
              model: {
                value: _vm.keyword,
                callback: function($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword"
              }
            },
            [
              _c(
                "div",
                {
                  attrs: { slot: "right-icon" },
                  on: { click: _vm.handlerOnSearch },
                  slot: "right-icon"
                },
                [_c("i", { staticClass: "me-icon-search btn" })]
              )
            ]
          )
        ],
        1
      ),
      !_vm.model
        ? _c(
            "van-cell",
            {
              staticClass: "me-friends__group",
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "myGroups" })
                }
              }
            },
            [
              _c("i", { staticClass: "me-icon-group" }),
              _c("i", { staticClass: "me-icon-right" })
            ]
          )
        : _vm._e(),
      _vm.loading
        ? _c("van-loading", {
            staticClass: "loading",
            attrs: { color: "#33cc66" }
          })
        : !_vm.loading && _vm.list.length
        ? _c("contactList", {
            staticClass: "me-firends__contactlist",
            attrs: {
              list: _vm.list,
              oldMember: _vm.oldGroupMembers.members,
              isSelectModel: _vm.model
            },
            on: { change: _vm.handlerChangeSelected }
          })
        : _c("emptyData"),
      !_vm.model
        ? _c("Tabbar")
        : _c(
            "div",
            { staticClass: "userList" },
            [
              _c(
                "div",
                { staticClass: "users" },
                _vm._l(_vm.selectedUsers, function(item, index) {
                  return _c("Avatar", {
                    key: index,
                    attrs: { src: item.avatar, width: "40px", height: "40px" }
                  })
                }),
                1
              ),
              _c("van-button", {
                class: { primary: true, disable: !_vm.addUsers.length },
                attrs: {
                  loading: _vm.addUserloading,
                  type: "primary",
                  icon: "success"
                },
                on: {
                  click: function($event) {
                    _vm.addUsers.length ? _vm.handlerAdduserInGroup() : ""
                  }
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }