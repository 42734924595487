import http from "@/utils/http";
import {
  objectToUrlParams
} from "@/utils/url";

export function getFriends(params) {
  return http({
    url: '/public/queryFriend.do',
    params,
    withCredentials: false
  });
}
export function getNotices(params,id,type){
  return http({
    url: `/rest/user/message/${id}/${type}`,
    params
  })
}
export function replyNotice(tipId, status = 1) {
  return http({
    url: "public/replyNotice.do",
    method: "post",
    data: objectToUrlParams({
      tip_id: tipId,
      status,
      token: JSON.parse(localStorage.getItem('userinfo') || {}).token || ''
    })
  });
}
export function getGroups(params) {
  return http({
    url: "/public/v1/queryMemberRelationGroups.do",
    params,
    withCredentials: false
  });
}
export function searchGroups(params) {
  return http({
    url: "/public/v1/queryMemberRelationKeyGroups.do",
    params,
    withCredentials: false
  });
}
export function getQueryChatMessageEXT(params) {
  return http({
    url: "/melinked/msg/queryChatMessageEXT.do",
    params
  });
}
export function getChatRecordEXT(params) {
  return http({
    url: "/public/msg/queryChatRecordEXT.do",
    params
  });
}
export function senderGroupMSG(params) {
  return http({
    url: "/public/sendMsgtoMuc.do",
    method: "post",
    data: objectToUrlParams(params)
  });
}

export function getTransTarget(params) {
  return http({
    url: '/rest/translate/type',
    params
  })
}