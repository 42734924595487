var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-friends__list" },
    [
      _c(
        "van-index-bar",
        {
          attrs: {
            "index-list": Object.keys(_vm.formartList),
            "sticky-offset-top": 50
          }
        },
        [
          _vm._l(Object.keys(_vm.formartList), function(key, index) {
            return [
              _c("van-index-anchor", { key: index, attrs: { index: key } }),
              _vm._l(_vm.formartList[key], function(contact, _key) {
                return _c(
                  "van-cell",
                  {
                    key: contact.id,
                    on: {
                      click: function($event) {
                        _vm.isSelectModel
                          ? _vm.handlerSelectedContact(key, _key)
                          : ""
                      }
                    }
                  },
                  [
                    _vm.isSelectModel &&
                    (contact.selected ||
                      _vm.oldMember.some(function(el) {
                        return el.id === contact.senderId
                      }))
                      ? _c("van-icon", {
                          staticClass: "selected",
                          attrs: {
                            slot: "right-icon",
                            name: "checked",
                            size: "20px",
                            color: "#33cc66"
                          },
                          slot: "right-icon"
                        })
                      : _vm.isSelectModel
                      ? _c("van-icon", {
                          staticClass: "selected",
                          attrs: {
                            slot: "right-icon",
                            name: "checked",
                            size: "20px",
                            color: "#e5e5e5"
                          },
                          slot: "right-icon"
                        })
                      : _vm._e(),
                    _c("Avatar", {
                      attrs: {
                        src: contact.senderPhoto,
                        width: "36px",
                        height: "36px",
                        isGroup: contact.isGroup,
                        id: contact.senderId
                      }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "name",
                        on: {
                          click: function($event) {
                            !_vm.isSelectModel
                              ? _vm.handlerToChat(
                                  {
                                    id: contact.senderId,
                                    name: contact.senderName
                                  },
                                  contact.isGroup ? "group" : "user"
                                )
                              : ""
                          }
                        }
                      },
                      [_vm._v(_vm._s(contact.senderName))]
                    ),
                    contact.isGroup && contact.owner
                      ? _c("i", { staticClass: "me-icon-user-solid" })
                      : _vm._e()
                  ],
                  1
                )
              })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }